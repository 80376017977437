:root{
    --takenode-blue: #4c7ef2;
    --background: #4c7ef2;
    --editor-purple: #fd5c21;
}

*{
    margin: 0;
    padding: 0;
    font-family: 'Futura', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body{
    background-color: var(--background);
}

footer{
    margin: 10vmin 0 0 0;
}

.locale_chooser{
    margin: -8vmin 10vmin 10vmin 10vmin;
    text-align: center;
}

.App {
    text-align: center;

    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding: 10vmin;
}

h1{
    font-size: calc(10px + 10vmin);
    line-height: 0.9;
    margin-top: 2vmin;
}

h2{
    margin: 10vh 0 2vh 0;
    font-size: calc(10px + 4vmin);
}

p{
    max-width: 100%;
    width: calc(40vh + 15vw);
}

a{
    color: white;
}
a:hover{
    color: rgba(255,255,255,0.8);
}

.HeaderImage{
    height: calc(10px + 20vmin);
    max-width: 100%;
    color: #fff;
    filter: brightness(0) invert(1);
}

.FinishedFrame {
    height: 36vh;
    margin: 2vh;
}

.FrameChooser .frame{
    position: relative;
    display: inline-block;
    font-size: 0;

    margin: 2vh;
    cursor: pointer;
    /* box-shadow: 0 0 0 1vh rgba(255,255,255,0); */
    transition: transform 0.2s ease;
}
.FrameChooser .frame img{
    height: 12vh;
    pointer-events: none;
}
.FrameChooser .frame:hover{
    transform-origin: center center;
    transform: scale(1.1);
}
/* .FrameChooser .frame.choosen{
    box-shadow: 0 0 0 1vh white;
} */
.FrameChooser .frame.choosen:after {
    content: "";
    position: absolute;
    top: -0.5vh;
    right: -0.5vh;
    bottom: -0.5vh;
    left: -0.5vh;
    box-shadow: 0 0 0 0.5vh white;
}

button,
.labelButton{
    position: relative;
    outline: none;
    border: none;
    margin: 2vmin;
    padding: 1vh 2vh;
    font-size: calc(10px + 2vmin);
    font-weight: bold;
    cursor: pointer;

    background: white;
    color: var(--takenode-blue);
    transition: transform 0.2s ease;
}
button.isInRow{
    margin: 1vmin;
}
button:hover,
.labelButton:hover{
    transform-origin: center center;
    transform: scale(1.05);
}
button.choosen:after {
    content: "";
    position: absolute;
    top: -0.5vh;
    right: -0.5vh;
    bottom: -0.5vh;
    left: -0.5vh;
    box-shadow: 0 0 0 0.5vh white;
}

.labelButton img{
    display: inline;
    height: calc(10px + 4vmin);
    vertical-align: middle;
    margin: 0 2vh 0 -1vh;
}
.labelButton span{
    vertical-align: middle;
}


.droparea{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: bold;
    font-size: calc(10px + 10vmin);

    background: rgba(20, 0, 34, 0.7);
    backdrop-filter: blur(10px);

    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s ease;
}
.droparea.active{
    opacity: 1;
}


.Editor{
    pointer-events: all;
    position: relative;
    margin: 2vh;
    font-size: 0;
    cursor: move;
    overflow: hidden;
    background: var(--editor-purple);
}
.Editor .background{
    pointer-events: none; 
    position: absolute;   
    top: 50%;
    left: 50%;
}
.Editor .foreground{
    pointer-events: none;
    position: relative;
    width: 36vh;
    height: 36vh;
}
.Editor .hashtag{
    pointer-events: none; 
    position: absolute;   
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

